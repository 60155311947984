import React, { useState, useRef } from 'react';
import { FiUpload } from 'react-icons/fi';
import styles from './styles.css';
import image from '../../logo/user-default.png';
const UserInfo = () => {
  const defaultUserImage = image;
  const [userImage, setUserImage] = useState(
    localStorage.getItem('user_image') || defaultUserImage,
  );

  const fileInputRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageSrc = e.target.result;
        localStorage.setItem('user_image', imageSrc);
        setUserImage(imageSrc);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      // Trigger the file input click when the image is clicked
      fileInputRef.current.click();
    }
  };

  return (
    <div className="user-profile">
      <div className="user-avatar" onClick={handleImageClick}>
        {userImage ? <img src={userImage} alt="User Avatar" /> : null}
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <div className="user-info">
        <p className="user-name">
          <strong>{localStorage.name}</strong>
        </p>
        <p className="user-oab-card">
          <strong>OAB: {localStorage.oab_card}</strong>
        </p>
      </div>
    </div>
  );
};

export default UserInfo;
