import Dashboard from './Components/Dashboard/Dashboard';
import LoginDashboard from './Components/DashboardLogin/DashBoardLogin';
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import style from './style.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
              <ProtectedRoute><Home /></ProtectedRoute>
          }
        />
        <Route
          path="/7b2d8a41ad4bd043f51d4ed6722192be"
          element={<LoginDashboard />}
        />
        <Route
          path="/d581d6513405eb1e770c56da9e6d9cab"
          element={<Dashboard />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
