import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './styles.css';

const Dashboard = () => {
  const token_dash = localStorage.getItem('token_dash');
  const [searchQuery, setSearchQuery] = useState('');
  const [advogadoData, setAdvogadoData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedAdvogadoData, setEditedAdvogadoData] = useState({});
  const [error, setError] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [deletingOABCard, setDeletingOABCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [success, setSuccess] = useState(null);
  const [createAdvogadoData, setCreateAdvogadoData] = useState({
    oab_card: '',
    name: '',
    security_number: '',
    cpf: '',
    telephone: '',
  });

  const navigate = useNavigate();
  const openConfirmationModal = (oabCard) => {
    setDeletingOABCard(oabCard);
    setIsConfirmationModalOpen(true);
  };
  const closeConfirmationModal = () => {
    setDeletingOABCard(null);
    setIsConfirmationModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const clearMessages = () => {
    setError(null);
    setSuccess(null);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://server.oabdf.org.br/search-lawyer/${searchQuery}`,
      );
      setAdvogadoData(response.data);
      setEditedAdvogadoData(response.data);
      setIsEditing(false);
      setError(null);
      setTimeout(clearMessages, 5000);
    } catch (error) {
      setAdvogadoData(null);
      setEditedAdvogadoData({});
      setIsEditing(false);
      setError('Advogado não encontrado');
      setTimeout(clearMessages, 5000);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleDelete = async () => {
    try {
      // Envie uma solicitação DELETE para excluir o advogado usando deletingOABCard
      await axios.delete(
        `https://server.oabdf.org.br/delete-lawyer/${deletingOABCard}`,
      );

      await axios.post('https://server.oabdf.org.br/logs', {
        logMessage: `${localStorage.getItem(
          'username',
        )} deletou o advogado ${deletingOABCard}`,
      });

      // Limpe o estado e feche o modal de confirmação
      setAdvogadoData(null);
      setEditedAdvogadoData({});
      setIsEditing(false);
      setError(null);
      closeConfirmationModal();
      setSuccess('Advogado Excluído com Sucesso');
      setTimeout(clearMessages, 5000);
    } catch (error) {
      setSuccess(null);
      setError('Erro ao excluir o advogado');
      setTimeout(clearMessages, 5000);
    }
  };

  const handleSave = async () => {
    const editedFields = {};

    for (const key in editedAdvogadoData) {
      if (
        editedAdvogadoData.hasOwnProperty(key) &&
        editedAdvogadoData[key] !== advogadoData[key]
      ) {
        editedFields[key] = editedAdvogadoData[key];
      }
    }
    try {
      const response = await axios.put(
        `https://server.oabdf.org.br/update-lawyer/${searchQuery}`,
        {
          ...editedAdvogadoData,
        },
      );
      if (
        response.data.message === 'Dados do advogado atualizados com sucesso'
      ) {
        setAdvogadoData(editedAdvogadoData);
        setIsEditing(false);
        setError(null);
        setSuccess('Informações Atualizadas com Sucesso');
        setTimeout(clearMessages, 5000);

        // Agora envie o logMessage para a rota /logs
        await axios.post('https://server.oabdf.org.br/logs', {
          logMessage: `${localStorage.getItem(
            'username',
          )} editou o advogado ${searchQuery} -> ${JSON.stringify(
            editedFields,
          )}`,
        });
      } else {
        setError('Erro ao salvar as edições');
      }
    } catch (error) {
      setSuccess(null);
      setError('Erro ao salvar as edições');
      setTimeout(clearMessages, 5000);
    }
  };

  const handleCreate = async () => {
    try {
      const response = await axios.post(
        'https://server.oabdf.org.br/create-lawyer',
        createAdvogadoData,
      );
      if (response.data.message === 'Advogado criado com sucesso') {
        console.log(response.data.message);

        setCreateAdvogadoData({
          oab_card: '',
          name: '',
          security_number: '',
          cpf: '',
          telephone: '',
        });
        closeModal();
        setSuccess('Advogado inserido na base de dados');
        setTimeout(clearMessages, 5000);

        // Registre o evento de criação na rota /logs, se necessário
        // Certifique-se de ajustar esta parte de acordo com o que você deseja registrar
        await axios.post('https://server.oabdf.org.br/logs', {
          logMessage: `${localStorage.getItem(
            'username',
          )} criou um advogado -> ${JSON.stringify(createAdvogadoData)}`,
        });
      } else {
        setError('Erro ao criar advogado');
        setTimeout(clearMessages, 5000);
      }
    } catch (error) {
      console.error('Erro ao criar advogado:', error);
      setError('Erro ao criar advogado');
      setTimeout(clearMessages, 5000);
    }
  };

  const handleCreateInputChange = (fieldName, value) => {
    if (fieldName === 'name') {
      value = value.toUpperCase();
    }

    if (fieldName === 'cpf') {
      // Remova todos os caracteres não numéricos do valor
      value = value.replace(/\D/g, ''); // Isso remove todos os caracteres não numéricos
    }

    // Atualize o estado
    setCreateAdvogadoData({
      ...createAdvogadoData,
      [fieldName]: value,
    });
  };

  const handleSaveInputChange = (fieldName, value) => {
    if (fieldName === 'name') {
      value = value.toUpperCase();
    }

    if (fieldName === 'cpf') {
      // Remova todos os caracteres não numéricos do valor
      value = value.replace(/\D/g, ''); // Isso remove todos os caracteres não numéricos
    }

    // Atualize o estado de editedAdvogadoData
    setEditedAdvogadoData({
      ...editedAdvogadoData,
      [fieldName]: value,
    });
  };

  const handleLogout = () => {
    // Remova o token do Local Storage
    localStorage.removeItem('token_dash');
    localStorage.removeItem('username');
    // Redirecione o usuário de volta para a página de login
    navigate('/7b2d8a41ad4bd043f51d4ed6722192be');
  };

  if (!token_dash) {
    return <Navigate to="/7b2d8a41ad4bd043f51d4ed6722192be" />;
  }

  return (
    <div className="dashboard">
      <h1 style={{ color: 'white' }}>Dashboard</h1>
      <h3 style={{ color: 'white' }}>Bem-vindo(a), {localStorage.username}.</h3>

      <div className="search-container">
        <input
          type="text"
          placeholder="Informe o n° da carteira OAB"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={handleSearch}>Buscar</button>
        <button onClick={openModal}>Adicionar</button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Adicionar Advogado</h3>
            <div className="advogado-data">
              <table>
                <tbody>
                  <tr>
                    <td>OAB Card:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Insira a carteira OAB"
                        required
                        value={createAdvogadoData.oab_card}
                        onChange={(e) =>
                          handleCreateInputChange('oab_card', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Nome:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Insira o nome"
                        required
                        value={createAdvogadoData.name}
                        onChange={(e) =>
                          handleCreateInputChange('name', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Número de Segurança:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Informe o número de segurança"
                        required
                        value={createAdvogadoData.security_number}
                        onChange={(e) =>
                          handleCreateInputChange(
                            'security_number',
                            e.target.value,
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CPF:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="(Apenas Números)"
                        required
                        value={createAdvogadoData.cpf}
                        onChange={(e) =>
                          handleCreateInputChange('cpf', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Telefone:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Insira o número de telefone"
                        required
                        value={createAdvogadoData.telephone}
                        onChange={(e) =>
                          handleCreateInputChange('telephone', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="modal-buttons">
                <button className="button" onClick={closeModal}>
                  Cancelar
                </button>
                <button className="button btn-color" onClick={handleCreate}>
                  Adicionar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {advogadoData && (
        <div className="advogado-data">
          {isEditing ? (
            <>
              <h4 style={{ color: 'white' }}>Editar</h4>
              <table>
                <tbody>
                  <tr>
                    <td>OAB Card:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Insira a carteira OAB"
                        value={editedAdvogadoData.oab_card || ''}
                        onChange={(e) =>
                          handleSaveInputChange('oab_card', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Nome:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Insira o nome"
                        value={editedAdvogadoData.name || ''}
                        onChange={(e) =>
                          handleSaveInputChange('name', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Número de Segurança:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Insira o número de segurança"
                        value={editedAdvogadoData.security_number || ''}
                        onChange={(e) =>
                          handleSaveInputChange(
                            'security_number',
                            e.target.value,
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>CPF:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="CPF (Apenas números)"
                        value={editedAdvogadoData.cpf || ''}
                        onChange={(e) =>
                          handleSaveInputChange('cpf', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Telefone:</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Telefone"
                        value={editedAdvogadoData.telephone || ''}
                        onChange={(e) =>
                          handleSaveInputChange('telephone', e.target.value)
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <h4 style={{ color: 'white' }}>Dados do Advogado:</h4>
              <table>
                <tbody>
                  <tr>
                    <td>OAB Card:</td>
                    <td>{advogadoData.oab_card}</td>
                  </tr>
                  <tr>
                    <td>Nome:</td>
                    <td>{advogadoData.name}</td>
                  </tr>
                  <tr>
                    <td>Número de Segurança:</td>
                    <td>{advogadoData.security_number}</td>
                  </tr>
                  <tr>
                    <td>CPF:</td>
                    <td>{advogadoData.cpf}</td>
                  </tr>
                  <tr>
                    <td>Telefone:</td>
                    <td>{advogadoData.telephone}</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {!isEditing && <button onClick={handleEdit}>Editar</button>}
          {!isEditing && (
            <button
              onClick={() => openConfirmationModal(advogadoData.oab_card)}
            >
              Excluir
            </button>
          )}
          {isConfirmationModalOpen && (
            <div className="modal-overlay">
              <div className="modal">
                <h3>Exclusão</h3>
                <p>
                  Deseja excluir o advogado com a Carteira OAB:{' '}
                  {deletingOABCard}?
                </p>
                <div className="modal-buttons">
                  <button onClick={closeConfirmationModal}>Cancelar</button>
                  <button onClick={handleDelete} className="exclude-btn">
                    Excluir
                  </button>
                </div>
              </div>
            </div>
          )}

          {isEditing && <button onClick={handleSave}>Salvar</button>}
        </div>
      )}
      <button className="button-dash button" onClick={handleLogout}>
        Sair
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
    </div>
  );
};

export default Dashboard;
