import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './styles.css';
import { FaEye } from 'react-icons/fa';
import logo from '../../logo/logo.png';

const DashboardLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token_dash, setTokenDash] = useState(''); // Estado para armazenar o token
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showPassword, setshowPassword] = useState(false);

  const toggleSecurityNumberVisibility = () => {
    setshowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        'https://server.oabdf.org.br/login-dashboard',
        {
          username,
          password,
        },
      );

      setTokenDash(response.data.token_Dash);
      localStorage.setItem('token_dash', response.data.token_dash);
      localStorage.setItem('username', response.data.user);

      setSuccess('Login realizado com sucesso.');
      setUsername('');
      setPassword('');
      navigate('/d581d6513405eb1e770c56da9e6d9cab');
    } catch (error) {
      setError('Erro ao fazer login no Dashboard');
      console.error(error);
    }
  };

  return (
    <div className="login-form">
      <div className="login-header">
        <img src={logo} /> <h2>Login Dashboard</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Nome de Usuário</label>
          <input
            type="text"
            id="username"
            placeholder="Insira seu username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Senha:</label>
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder="Insira sua senha"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FaEye
              className="password-toggle-icon"
              onClick={toggleSecurityNumberVisibility}
            />
          </div>
        </div>
        <button type="submit">Login no Dashboard</button>
      </form>
      {success && <p style={{ color: 'green' }}>{success}</p>}
      {error && (
        <>
          <p style={{ color: 'red' }}>{error}</p>
        </>
      )}
    </div>
  );
};

export default DashboardLogin;
