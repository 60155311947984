import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

document.addEventListener('touchstart', function (event) {
  event.preventDefault();
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
