import React, { useState } from 'react';
import styles from './styles.css';

const ReportModal = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    name: localStorage.name || '',
    oab_card: localStorage.oab_card || '',
    email: '',
    mensagem: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { name, oab_card, email, mensagem } = formData;

    if (!name || !oab_card || !email || !mensagem) {
      setErrorMessage('Por favor, preencha todos os campos obrigatórios.');
      setTimeout(() => setErrorMessage(''), 5000); // Limpar mensagem de erro após 3 segundos
      return;
    }

    const maxFilesCount = 6;
    const maxTotalSize = 25 * 1024 * 1024;
    const allowedFileTypes = [
      '.pdf',
      '.doc',
      '.jpg',
      '.jpeg',
      '.png',
      '.mp4',
      '.webm',
      '.avi',
      '.mkv',
      '.mp3',
      '.wav',
      '.ogg',
      '.docx',
      '.txt',
      '.rtf',
      '.odt',
      '.ods',
      '.odp',
    ];

    let totalSize = 0;
    const allFiles = [...selectedFiles];

    if (allFiles.length > maxFilesCount) {
      setErrorMessage('Você pode selecionar no máximo 6 arquivos.');
      setTimeout(() => setErrorMessage(''), 5000);
      console.error('Você pode selecionar no máximo 6 arquivos.');
      return;
    }

    for (let i = 0; i < allFiles.length; i++) {
      const fileName = allFiles[i].name.toLowerCase();
      const fileExtension = '.' + fileName.split('.').pop();

      totalSize += allFiles[i].size;

      if (!allowedFileTypes.includes(fileExtension)) {
        setErrorMessage('Tipo de arquivo não suportado: ' + fileName);
        setTimeout(() => setErrorMessage(''), 5000);
        console.error('Tipo de arquivo não suportado: ' + fileName);
        return;
      }
    }

    if (totalSize > maxTotalSize) {
      setErrorMessage('O tamanho total dos arquivos não pode exceder 25MB.');
      setTimeout(() => setErrorMessage(''), 5000);
      console.error('O tamanho total dos arquivos não pode exceder 25MB.');
      return;
    }

    const formDataWithFiles = new FormData();
    formDataWithFiles.append('name', name);
    formDataWithFiles.append('oab_card', oab_card);
    formDataWithFiles.append('email', email);
    formDataWithFiles.append('mensagem', mensagem);

    // Adicione todos os arquivos, tanto da petição quanto dos anexos, com a mesma chave
    for (let i = 0; i < allFiles.length; i++) {
      formDataWithFiles.append('files', allFiles[i]);
    }

    try {
      const response = await fetch('https://server.oabdf.org.br/send-mail', {
        method: 'POST',
        body: formDataWithFiles,
      });

      if (response.ok) {
        setSuccessMessage('Email enviado com sucesso');
        setTimeout(() => setSuccessMessage(''), 5000);
        console.log('Email enviado com sucesso');
      }
    } catch (error) {
      setErrorMessage('Erro ao enviar email:');
      setTimeout(() => setErrorMessage(''), 5000);
      console.error('Erro ao enviar email:', error);
    }
  };

  return (
    <div className={`modal-overlay ${show ? 'show' : ''}`}>
      <form className="modal-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <input
            type="text"
            name="name"
            placeholder="Nome"
            required
            value={formData.name}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="oab_card"
            placeholder="Carteira da OAB"
            required
            value={formData.oab_card}
            onChange={handleInputChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Insira o seu Email"
            required
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <textarea
          name="mensagem"
          placeholder="Mensagem (limite de 500 caracteres)"
          maxLength="500"
          required
          value={formData.mensagem}
          onChange={handleInputChange}
        ></textarea>
        <label htmlFor="anexos" className="anexos-label">
          Anexar Arquivos e/ou Petição <br /> (máx 6 arquivos, 25MB no total):
        </label>
        <input
          type="file"
          id="anexos"
          name="anexos"
          multiple
          accept=".pdf, .doc, .jpg, .jpeg, .png, .mp4, .webm, .avi, .mkv, .mp3, .wav, .ogg, .docx, .txt, .rtf, .odt, .ods, .odp"
          onChange={handleFileChange}
        />
        <div className="modal-buttons">
          <button className="button" type="button" onClick={onClose}>
            Cancelar
          </button>
          <button className="button btn-color" type="submit">
            Enviar
          </button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {successMessage && <p className="success-message">{successMessage}</p>}
      </form>
    </div>
  );
};

export default ReportModal;
