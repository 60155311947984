import React, { useState, useEffect } from 'react';
import step1 from '../../img/step1.png';
import step2 from '../../img/step2.png';
import step3 from '../../img/step3.png';

const AddToHomeScreenButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isPC, setIsPC] = useState(false);
  const [isPWA, setIsPWA] = useState(false);

  const [showTutorial, setShowTutorial] = useState(false);
  const showTutorialModal = () => {
    setShowTutorial(true);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsPWA(true);
    } else {
      setIsPWA(false);
    }

    if (/android/i.test(userAgent)) {
      setIsAndroid(true);
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setIsIOS(true);
    } else {
      setIsPC(true);
    }
  }, []);

  useEffect(() => {
    // Adicione um ouvinte para o evento beforeinstallprompt
    window.addEventListener('beforeinstallprompt', (event) => {
      // Impedir que a solicitação padrão seja exibida
      event.preventDefault();
      // Armazenar a referência à solicitação para uso posterior
      setDeferredPrompt(event);
    });
  }, []);

  const addToHomeScreen = () => {
    if (deferredPrompt) {
      // Exibir a solicitação de instalação quando o usuário clicar no botão
      deferredPrompt.prompt();
      // Aguarde o usuário tomar uma decisão
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('O usuário aceitou a instalação');
        } else {
          console.log('O usuário recusou a instalação');
        }
        // Limpe a referência à solicitação
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      {!isPWA && (
        <>
          {(isAndroid || isPC) && (
            <button className="button btn-border" onClick={addToHomeScreen}>
              Instalar no Dispositivo
            </button>
          )}
          {isIOS && (
            <button className="button btn-border" onClick={showTutorialModal}>
              Como instalar (iPhone)
            </button>
          )}
          {showTutorial && (
            <div className="modal-overlay">
              <div className="modal">
                <h3>Tutorial instalação iPhone:</h3>
                <p style={{ textAlign: 'left' }}>
                  <strong>Passo 1:</strong> Toque no ícone "Compartilhar" na
                  parte inferior da tela. É um ícone quadrado com uma seta
                  apontando para cima.
                </p>
                <img src={step1} />
                <p style={{ textAlign: 'left' }}>
                  <strong>Passo 2:</strong> Role para baixo na lista de opções
                  de compartilhamento até encontrar "Adicionar à Tela Inicial" e
                  toque nele. Pode estar dentro da seção "Ações".
                </p>
                <img src={step2} />
                <p style={{ textAlign: 'left' }}>
                  <strong>Passo 3:</strong> Uma tela de configuração será
                  exibida. Você pode renomear o PWA se desejar, mas o nome
                  padrão geralmente é bom. Toque em "Adicionar" no canto
                  superior direito.
                </p>
                <img src={step3} />
                <div className="modal-buttons">
                  <button
                    className="button"
                    onClick={() => setShowTutorial(false)}
                  >
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AddToHomeScreenButton;
